
@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,900');
$primary: #ef589d; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

html {
  overflow: hidden;
  height: 100%;
}


body {
  overflow: auto;
  height: 100%;
	font-family: 'Maven Pro', sans-serif;

	background:linear-gradient(
		rgba(200,200,200,0.8),
		rgba(200,200,200,0.8)
		),
	 url('../img/bg.jpg');

  	background-size: cover;
	background-repeat: no-repeat;
	font-weight: bold;
	letter-spacing: 1px;
	word-spacing: 3px;
	line-height: 2em;

}

h1,h2,h3,h4 {
	font-weight: bold;
}

// unset bs3 setting
.modal-open {
 overflow: auto;
}


.top-pad{
	margin-top: 120px;
	font-size: 1.3em;
	color: $blk;
	h1,h2,h3,h4,h5,h6 {
		color: $blk;
		font-size: 1.5em;
	}

	@media(max-width:767px){
		margin-top: 85px;
		font-size: 1em;
	}
}


.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


/** NAV START **/
nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active, &:visited, {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover {
		background: lighten($primary, 10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk, 10%);
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 75%;
		font-size: 18px;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}
// sections
h1 {
	font-size: 2.5em;
	@media(max-width:767px){
		font-size: 1.5em;
	}
}
p {
	font-size: 1.3em;
	@media(max-width:767px){
		font-size: 1em;
	}
}
section {
	padding: 50px 0;
	@media(max-width:767px){
		padding:20px 0;
	}
}
.section-a {
	margin-top: 100px;
	background-repeat: no-repeat;
	text-align: center;
	.container {
		background-color: lighten($primary, 5%);
		box-shadow: 0px 0px 20px lighten($blk, 40%);
		border-radius: 15px;
		h1 {
			margin-top: 25%;
		}
	}
	@media(max-width:767px){
		margin-top: 70px;
	}

}
.section-b{
	padding: 150px 0;
	background-color: lighten($primary, 5%);
		img {
			border-radius: 15px;
			box-shadow: 0px 0px 20px lighten($blk, 40%);
		}
		h1 {
			margin-top: 15%;
		}
		@media(max-width:991px){
			padding:100px 0;
		}
		@media(max-width:767px){
			padding:50px 0;
		}
}
.section-c{
	padding: 150px 0;
	img {
		border-radius: 15px;
		box-shadow: 0px 0px 20px lighten($blk, 40%);
	}
	h1 {
		margin-top: 5%;
	}
	@media(max-width:991px){
		padding:100px 0;
	}
	@media(max-width:767px){
		padding:50px 0;
	}
}
.section-d{
  background:linear-gradient(
  	rgba(55,55,55,0.85),
  	rgba(55,55,55,0.85)
  	),
  url('../img/bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

	padding: 250px 0;
	img {
		border-radius: 15px;
		box-shadow: 0px 0px 20px lighten($blk, 40%);
	}
	h1 {
    color: $wht;
	}
  p{
    color: $wht;
  }
	@media(max-width:991px){
		padding:100px 0;
	}
	@media(max-width:767px){
		padding:50px 0;
	}
}
/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $blk;

	@media (max-width: 990px) {
		font-size: 13px;
	}

	a {
		color: $blk;
		white-space: nowrap;

		&:hover{
			color: lighten($blk, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $blk;
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-height: 100px;
	@media(max-width: 767px){
		max-height: 70px;
	}
}
